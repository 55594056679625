import React, { StrictMode, lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "@asgardeo/auth-react";
import { asgardeoConfig } from "./services/asgardeo-config";
import TagManager from "react-gtm-module";

// Lazy Load App for Better Performance
const App = lazy(() => import("./App"));


// Optimize Google Tag Manager (Load When Browser is Idle)
const tagManagerArgs = {
  gtmId: "GTM-KDS52CWV",
};

if ("requestIdleCallback" in window) {
  requestIdleCallback(() => TagManager.initialize(tagManagerArgs));
} else {
  setTimeout(() => TagManager.initialize(tagManagerArgs), 3000); // Fallback for older browsers
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider config={asgardeoConfig}>
            <Suspense fallback={<div>Loading...</div>}>
                <App />
            </Suspense>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </StrictMode>
);
